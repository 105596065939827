import {
  ClaimEventType,
  ClaimEventTypeSchema as ClaimV2EventTypeSchema,
  DateOfLossSchema,
  DateReceivedSchema,
} from '@eigtech/claims-v2-types'
import { VeriskStatusPublishConfirmedEventSchema } from '@eigtech/verisk-edi-interface-types'
import z from 'zod'
import {
  ClaimLossOfUseDetailsSchema,
  ClaimLossOfUseReceiptAddedDetailsSchema,
  ClaimLossOfUseReceiptRemovedDetailsSchema,
  ClaimReviewCompletedDetailsSchema,
  ClaimReviewRequestedDetailsSchema,
} from './claimChronicleDetails.js'
import { ClaimV1EventTypeSchema } from './claimV1Events.js'
import { ChronicleLabelSchema, ChronicleSchema } from './timeline.js'

export const ClaimV1ChronicleTypeSchema = z.enum([
  ...ClaimV1EventTypeSchema.exclude([
    'fieldAdjusterInspectionCompleted',
    'fieldAdjusterInspectionScheduled',
  ]).options,
  'inspectionCompleted',
  'inspectionScheduled',
])

export const ClaimV1ChronicleType = ClaimV1ChronicleTypeSchema.Enum
export type ClaimV1ChronicleTypeEnum = z.infer<typeof ClaimV1ChronicleTypeSchema>

export const ClaimV2ChronicleTypeSchema = ClaimV2EventTypeSchema
export const ClaimV2ChronicleType = ClaimV2ChronicleTypeSchema.Enum
export type ClaimV2ChronicleTypeEnum = z.infer<typeof ClaimV2ChronicleTypeSchema>

export const EstimateChronicleTypeSchema = z.enum([
  'estimateReceived',
  'carrierRejected',
  'carrierReviewed',
  'carrierReviewedWithExceptions',
  'clientApproved',
  'clientRejected',
  'qaApproved',
  'qaRejected',
])
export const EstimateChronicleType = EstimateChronicleTypeSchema.Enum
export type EstimateChronicleTypeEnum = z.infer<typeof EstimateChronicleTypeSchema>
export const isEstimateChronicleType = (type: string): type is EstimateChronicleTypeEnum =>
  EstimateChronicleTypeSchema.safeParse(type).success

export const ClaimAssignmentChronicleTypeSchema = z.enum([
  'carrierAssignedToClaim',
  'carrierUnassignedFromClaim',
  'coordinatorAssignedToClaim',
  'coordinatorUnassignedFromClaim',
  'fieldAdjusterAssignedToClaim',
  'fieldAdjusterUnassignedFromClaim',
  'jobAssignedToClaim',
  'jobUnassignedFromClaim',
  'legalRepAssignedToClaim',
  'legalRepUnassignedFromClaim',
  'policyHolderAssignedToClaim',
  'policyHolderUnassignedFromClaim',
  'primaryContactAssignedToClaim',
  'primaryContactUnassignedFromClaim',
  'projectManagerAssignedToClaim',
  'projectManagerUnassignedFromClaim',
  'reviewerAssignedToClaim',
  'reviewerUnassignedFromClaim',
  'inspectionTechnicianAssignedToClaim',
  'inspectionTechnicianUnassignedFromClaim',
])
export const ClaimAssignmentChronicleType = ClaimAssignmentChronicleTypeSchema.Enum
export type ClaimAssignmentChronicleTypeEnum = z.infer<typeof ClaimAssignmentChronicleTypeSchema>

export const ClaimMeetingChronicleTypeSchema = z.enum(['claimMeetingEnded'])
export const ClaimMeetingChronicleType = ClaimMeetingChronicleTypeSchema.Enum
export type ClaimMeetingChronicleTypeEnum = z.infer<typeof ClaimMeetingChronicleTypeSchema>

export const ClaimCreatedChronicleSchema = ChronicleSchema.merge(
  z.object({
    details: z.object({
      lossDate: DateOfLossSchema,
    }),
    label: ChronicleLabelSchema.optional(),
    type: z.literal(ClaimEventType.claimCreated),
  })
)
export type ClaimCreatedChronicle = z.infer<typeof ClaimCreatedChronicleSchema>
export const isClaimCreatedChronicle = (chronicle: unknown): chronicle is ClaimCreatedChronicle =>
  ClaimCreatedChronicleSchema.safeParse(chronicle).success

export const DateOfLossUpdatedChronicleSchema = ChronicleSchema.merge(
  z.object({
    details: z.object({
      lossDate: DateOfLossSchema,
    }),
    label: ChronicleLabelSchema.optional(),
    type: z.literal(ClaimEventType.dateOfLossUpdated),
  })
)
export type DateOfLossUpdatedChronicle = z.infer<typeof DateOfLossUpdatedChronicleSchema>
export const isDateOfLossUpdatedChronicle = (
  chronicle: unknown
): chronicle is DateOfLossUpdatedChronicle =>
  DateOfLossUpdatedChronicleSchema.safeParse(chronicle).success

export const DateReceivedUpdatedChronicleSchema = ChronicleSchema.merge(
  z.object({
    details: z.object({
      dateReceived: DateReceivedSchema,
    }),
    label: ChronicleLabelSchema.optional(),
    type: z.literal(ClaimEventType.dateReceivedUpdated),
  })
)
export type DateReceivedUpdatedChronicle = z.infer<typeof DateReceivedUpdatedChronicleSchema>
export const isDateReceivedUpdatedChronicle = (
  chronicle: unknown
): chronicle is DateReceivedUpdatedChronicle =>
  DateReceivedUpdatedChronicleSchema.safeParse(chronicle).success

export const VeriskStatusPublishedChronicleSchema = ChronicleSchema.merge(
  z.object({
    details: VeriskStatusPublishConfirmedEventSchema.shape.publication.pick({ vediImport: true }),
    label: ChronicleLabelSchema.optional(),
    type: z.literal('statusPublishConfirmed'),
  })
)
export type VeriskStatusPublishedChronicle = z.infer<typeof VeriskStatusPublishedChronicleSchema>
export const isVeriskStatusPublishedChronicle = (
  chronicle: unknown
): chronicle is VeriskStatusPublishedChronicle =>
  VeriskStatusPublishedChronicleSchema.safeParse(chronicle).success

export const InspectionChronicleSchema = ChronicleSchema.merge(
  z.object({
    details: z.object({
      inspection: z
        .object({
          dateCompleted: z.string().datetime().optional(),
          scheduledDate: z.string().datetime().optional(),
        })
        .passthrough(),
    }),
    label: ChronicleLabelSchema.optional(),
    type: z.enum([
      ClaimEventType.inspectionCanceled,
      ClaimEventType.inspectionCompleted,
      ClaimEventType.inspectionScheduled,
    ]),
  })
)
export type InspectionChronicle = z.infer<typeof InspectionChronicleSchema>
export const isInspectionChronicle = (chronicle: unknown): chronicle is InspectionChronicle =>
  InspectionChronicleSchema.safeParse(chronicle).success

export const LossOfUseChronicleSchema = ChronicleSchema.merge(
  z.object({
    details: ClaimLossOfUseDetailsSchema.passthrough(),
    label: ChronicleLabelSchema.optional(),
    type: z.enum([
      ClaimEventType.lossOfUseAdded,
      ClaimEventType.lossOfUseApproved,
      ClaimEventType.lossOfUseCanceled,
      ClaimEventType.lossOfUseDenied,
      ClaimEventType.lossOfUsePending,
    ]),
  })
)
export type LossOfUseChronicle = z.infer<typeof LossOfUseChronicleSchema>
export const isLossOfUseChronicle = (chronicle: unknown): chronicle is LossOfUseChronicle =>
  LossOfUseChronicleSchema.safeParse(chronicle).success

export const LossOfUseReceiptAddedChronicleSchema = ChronicleSchema.merge(
  z.object({
    details: ClaimLossOfUseReceiptAddedDetailsSchema.passthrough(),
    label: ChronicleLabelSchema.optional(),
    type: z.enum([ClaimEventType.lossOfUseReceiptAdded]),
  })
)
export type LossOfUseReceiptAddedChronicle = z.infer<typeof LossOfUseReceiptAddedChronicleSchema>
export const isLossOfUseReceiptAddedChronicle = (
  chronicle: unknown
): chronicle is LossOfUseReceiptAddedChronicle =>
  LossOfUseReceiptAddedChronicleSchema.safeParse(chronicle).success

export const LossOfUseReceiptRemovedChronicleSchema = ChronicleSchema.merge(
  z.object({
    details: ClaimLossOfUseReceiptRemovedDetailsSchema.passthrough(),
    label: ChronicleLabelSchema.optional(),
    type: z.enum([ClaimEventType.lossOfUseReceiptRemoved]),
  })
)

export type LossOfUseReceiptRemovedChronicle = z.infer<
  typeof LossOfUseReceiptRemovedChronicleSchema
>
export const isLossOfUseReceiptRemovedChronicle = (
  chronicle: unknown
): chronicle is LossOfUseReceiptRemovedChronicle =>
  LossOfUseReceiptRemovedChronicleSchema.safeParse(chronicle).success

export const ClaimReviewRequestedChronicleSchema = ChronicleSchema.merge(
  z.object({
    details: ClaimReviewRequestedDetailsSchema.passthrough(),
    label: ChronicleLabelSchema.optional(),
    type: z.enum([ClaimEventType.reviewRequested]),
  })
)

export type ClaimReviewRequestedChronicle = z.infer<typeof ClaimReviewRequestedChronicleSchema>
export const isClaimReviewRequestedChronicle = (
  chronicle: unknown
): chronicle is ClaimReviewRequestedChronicle =>
  ClaimReviewRequestedChronicleSchema.safeParse(chronicle).success

export const ClaimReviewCompletedChronicleSchema = ChronicleSchema.merge(
  z.object({
    details: ClaimReviewCompletedDetailsSchema.passthrough(),
    label: ChronicleLabelSchema.optional(),
    type: z.enum([ClaimEventType.reviewCompleted]),
  })
)

export type ClaimReviewCompletedChronicle = z.infer<typeof ClaimReviewCompletedChronicleSchema>
export const isClaimReviewCompletedChronicle = (
  chronicle: unknown
): chronicle is ClaimReviewCompletedChronicle =>
  ClaimReviewCompletedChronicleSchema.safeParse(chronicle).success

export type ClaimChronicleTypeEnum =
  | ClaimV1ChronicleTypeEnum
  | ClaimV2ChronicleTypeEnum
  | ClaimAssignmentChronicleTypeEnum
  | EstimateChronicleTypeEnum
