import z from 'zod'
import { TimelineEntityTypeSchema } from '../../timeline/index.js'

export const SyncTimelinesApiSchema = {
  params: z.object({
    entityType: TimelineEntityTypeSchema,
  }),
  response: z.object({
    executionArn: z.string(),
  }),
}

export type SyncTimelinesRequest = z.infer<typeof SyncTimelinesApiSchema.params>
export type SyncTimelinesResponse = z.infer<typeof SyncTimelinesApiSchema.response>
