/**
 * These are the possible status events that can occur in XA. Some of these have to be enabled by a User Administrator.
 * Refer to https://xactanalysis.helpdocs.io/l/enUS/article/klioir12y9-statuses
 */
export enum ControlPointType {
  /**
   * The date and time the assignment enters the Assignment Queue
   */
  assignmentEnteredQueue = 'Assignment Entered Queue',
  /**
   * The assignment is in the Assignment Queue waiting to be assigned to an assignee
   */
  inQueue = 'In Queue',
  /**
   * The assignment is in the Assignment Queue waiting to be assigned to an assignee (Xactimate user).
   */
  inAssignQueue = 'In Assign Queue',
  /**
   * The assignment is in the Assignment Queue waiting to be assigned to an assignee (Xactimate user).
   */
  enteredAssignmentQueue = 'Entered Assignment Queue',
  /**
   * The assignment in the queue is assigned to an assignee (Xactimate user) and leaves the Assignment Queue.
   */
  assignedFromQueue = 'Assigned From Queue',
  /**
   * The assignment in the queue is assigned to an assignee (Xactimate user) and leaves the Assignment Queue.
   */
  assignedOutOfAssignmentQueue = 'Assigned Out of Assignment Queue',
  /**
   * The date the assignment was first received into the Assignment Queue or assigned to an adjusterassessor or contractorbuilder. If the assignment was sent to the Assignment Queue first, the Received date is no longer updated (the Reassignment, Referral, or Collaboration statuses are used instead).XactAnalysis updates this status automatically when the assignment first enters the Assignment Queue or when it is first sent to an adjuster or contractor.
   */
  assignmentReceived = 'Assignment Received',
  /**
   * The date the assignment was first received into the Assignment Queue or assigned to an adjusterassessor or contractorbuilder. If the assignment was sent to the Assignment Queue first, the Received date is updated when it is assigned to an adjusterassessor or contractorbuilder. After being assigned the first time, the Received date is no longer updated (the Reassignment, Referral, or Collaboration statuses are used instead).
   */
  received = 'Received',
  /**
   * The date the assignment was first received into the Assignment Queue or assigned to an adjuster or contractor. If the assignment was sent to the Assignment Queue first, the Received date is no longer updated (the Reassignment, Referral, or Collaboration statuses are used instead).XactAnalysis updates this status automatically when the assignment first enters the Assignment Queue or when it is first sent to an adjuster or contractor.
   */
  estimateReceived = 'Estimate Received',
  /**
   * The assignment is assigned to an assignee (Xactimate user) and the assignee is not yet notified. This status appears in the Detail Summary when the assignment has been assigned (Received). The name and email address of the person who assigned it appears below this status.
   * @see notificationPending
   */
  notifyPending = 'Notify Pending',
  /**
   * The above is the API documentation, the below is what actually happens
   */
  notificationPending = 'Notification Pending',
  /**
   * An adjuster or contractor hasn't added a note to an assignment by a specified time.
   */
  noteAddedInXactAnalysisSp = 'Note Added in XactAnalysis SP',
  /**
   * The date and time the assignment's assignee (Xactimate user) is notified.
   */
  contractorNotified = 'Contractor Notified',
  /**
   * Estimate Review Queue Rules,XactAnalysis updates this event automatically when the assignee is notified.
   */
  notified = 'Notified',
  /**
   * The date and time the assignment's assignee (Xactimate user) is notified.
   */
  claimRepNotified = 'Claim Rep Notified',
  /**
   * The date and time the assignment’s assignee (Xactimate user) is notified.
   */
  notificationSent = 'Notification Sent',
  /**
   * Assignment Tracking Personal Rule,XactAnalysis updates this event automatically when the assignment is downloaded in Xactimate.
   */
  assignmentDelivered = 'Assignment Delivered',
  /**
   * The date and time the assignment is downloaded to Xactimate by the assignee.XactAnalysis updates this event automatically when the assignment is downloaded in Xactimate.
   */
  delivered = 'Delivered',
  /**
   * The date and time the recipient (Xactimate user) returns the estimate to XactAnalysis before it is complete.
   */
  inProgressEstimateReturnedToXactNet = 'In-Progress Estimate Returned to XactNet',
  /**
   * The date and time the first report is uploaded to XactAnalysis from Xactimate.
   */
  firstReportUploaded = 'First Report Uploaded',
  /**
   * The completed estimate is uploaded to XactAnalysis from Xactimate and is waiting to be exported to the carrier.
   */
  returnedPending = 'Returned Pending',
  /**
   * The date and time the completed estimate is uploaded to XactAnalysis from Xactimate.XactAnalysis updates this event automatically.
   */
  returned = 'Returned',
  /**
   * The completed estimate is uploaded to XactAnalysis from Xactimate. This is updated automatically and appears in the Detail Summary.
   */
  firstEstimateUploadAboveZero = 'First Estimate Upload > $0',
  /**
   * The date and time the estimate is uploaded to XactAnalysis from Xactimate.
   */
  estimateReturnedToXactAnalysis = 'Estimate Returned to XactAnalysis',
  /**
   * The date and time the estimate is returned to the carrier.
   */
  estimateReturnedToCarrier = 'Estimate Returned to Carrier',
  /**
   * The date and time the corrected estimate is uploaded to XactAnalysis.XactAnalysis updates this status automatically when a corrected estimate is uploaded from Xactimate.
   */
  corrected = 'Corrected',
  /**
   * The date and time the corrected estimate is uploaded to XactAnalysis.
   */
  correctedEstimateUploaded = 'Corrected Estimate Uploaded',
  /**
   * The date and time the corrected estimate is uploaded to XactAnalysis.
   */
  correctionReturnedToXactAnalysis = 'Correction Returned to XactAnalysis',
  /**
   * The date and time the corrected estimate is uploaded to XactAnalysis.
   */
  correctionReceived = 'Correction Received',
  /**
   * The assignment is canceled in XactAnalysis or via EDI by the carrier and insurer.
   */
  canceled = 'Canceled',
  /**
   * The assignment is canceled in XactAnalysis or via EDI by the carrier.
   */
  assignmentCanceled = 'Assignment Canceled',
  /**
   * The date and time XactAnalysis receives a cancellation for the assignment.
   */
  cancellationReceived = 'Cancellation Received',
  /**
   * The date and time the assignee is notified of the cancelled assignment.
   */
  adjusterContractorNotifiedOfCancellation = 'Adjuster/Contractor Notified of Cancellation',
  /**
   * The date and time the assignment is rejected or deleted by an assignee (Xactimate user) in Xactimate. This status appears in the Detail Summary.
   */
  rejected = 'Rejected',
  /**
   * The date and time the assignment is rejected or deleted by an assignee (Xactimate user) in Xactimate. This status appears in the Detail Summary.
   */
  assignmentRejected = 'Assignment Rejected',
  /**
   * The date and time the assignment is rejected or deleted by an assignee (Xactimate user) in Xactimate.
   */
  rejectedByRecipient = 'Rejected by Recipient',
  /**
   * A roof measurement is sent to a roof measurement provider.
   */
  roofMeasurementAnalysisSent = 'Roof Measurement Analysis Sent',
  /**
   * A roof measurement is completed and returned from a roof measurement provider.
   */
  roofMeasurementAnalysisCompleted = 'Roof Measurement Analysis Completed',
  /**
   * The assignment is not marked Job Not Sold and is marked Complete. You can exclude this type of assignment from notifications in Program Rules and Elapsed Time Rules by selecting this status.
   */
  jobCompletedExcludesNotSold = 'Job Completed excludes Not Sold',
  /**
   * The estimate entered the Estimate Review Queue.
   */
  enteredCarrierReviewQueue = 'Entered Carrier Review Queue',
  /**
   * The date and time the estimate is uploaded and submitted to the Estimate Review Queue.
   */
  notYetReviewed = 'Not Yet Reviewed',
  /**
   * A reviewer is assigned.
   */
  reviewerAssigned = 'Reviewer Assigned',
  /**
   * Reviewer has been changed.
   */
  reassigned = 'Reassigned',
  /**
   * A reviewer is assigned.
   */
  carrierReviewerAssigned = 'Carrier Reviewer Assigned',
  /**
   * A reviewer is assigned, but has not yet updated the review status.
   */
  reviewPendingAReviewerIsAssigned = 'Review Pending A reviewer is assigned',
  /**
   * The reviewer accepts the assigned estimate review.
   */
  reviewAccepted = 'Review Accepted',
  /**
   * Only for multiple reviewers, then the status is Review Not Pending.
   */
  reviewNotPending = 'Review Not Pending',
  /**
   * The reviewer marks the estimate as Estimate Reviewed in the Details tab. This status appears green in the Detail Summary.
   */
  reviewed = 'Reviewed',
  /**
   * The reviewer marks the estimate as Estimate Reviewed in the Details tab. This status appears green in the Detail Summary.
   */
  estimateReviewComplete = 'Estimate Review Complete',
  /**
   * The reviewer marks the estimate as Estimate Reviewed in the Details tab. This status appears green in the Detail Summary.
   */
  reviewCompleted = 'Review Completed',
  /**
   * The reviewer marks the estimate as Estimate Reviewed in the Details tab. This status appears green in the Detail Summary.
   */
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  carrierReviewed = 'Reviewed',
  /**
   * The reviewer marks the estimate as Estimate Reviewed in the Details tab. This status appears green in the Detail Summary.
   */
  reviewedWithExceptions = 'Reviewed with Exceptions',
  /**
   * The reviewer marks the estimate as Estimate Reviewed with Exceptions in the Details tab. This status appears yellow in the Detail Summary.
   */
  estimateReviewCompleteWithExceptions = 'Estimate Review Complete with Exceptions',
  /**
   * The reviewer marks the estimate as Estimate Reviewed with Exceptions in the Details tab. This status appears yellow in the Detail Summary.
   */
  carrierReviewedWithExceptions = 'Carrier Reviewed with Exceptions',
  /**
   * The reviewer marks the estimate as Estimate Reviewed with Exceptions in the Details tab. This status appears yellow in the Detail Summary.
   */
  reviewCompletedWithExceptions = 'Review Completed with Exceptions',
  /**
   * The reviewer rejected the estimate. This status appears red in the Detail Summary. When enabled, this status replaces Estimate Reviewed With Exceptions
   */
  estimateRejected = 'Estimate Rejected',
  /**
   * The date and time the estimate is uploaded and submitted to the Estimate Review Queue.
   */
  estimateNotReviewed = 'Estimate Not Reviewed',
  /**
   * The estimate is authorized by a reviewer. This status appears green in the Detail Summary. When enabled, this status replaces Estimate Reviewed
   */
  estimateAuthorizedDetailsTab = 'Estimate Authorized Details tab',
  /**
   * The estimate is reviewed, but client approval is still needed. This status appears yellow in the Detail Summary
   */
  estimateReviewedPendingClientDecision = 'Estimate Reviewed Pending Client Decision',
  /**
   * Details tab,The estimate is authorized, but a final cost approval is needed. This status appears yellow in the Detail Summary.
   */
  estimateAuthorizedPendingFinalValidationOfCosts = 'Estimate Authorized Pending Final Validation of Costs',
  /**
   * The reviewer updates this status in the Details tab. This status appears yellow in the Detail Summary.
   */
  estimateAuthorizedPendingPreStartWorkDetailsTab = 'Estimate Authorized Pending Pre-Start Work Details tab',
  /**
   * A document is uploaded to the assignment in XactAnalysis. This status is also updated when an in-progress assignment in Xactimate is uploaded with documents.
   */
  documentsReceived = 'Documents Received',
  /**
   * An inspection is scheduled.
   */
  plannedInspectionDate = 'Planned Inspection',
  /**
   * The assignment is ready to send to an adjuster/contractor.
   */
  readyForAssignment = 'Ready for Assignment',
  /**
   * Additional review is needed.
   */
  attentionNeeded = 'Attention Needed',
  /**
   * An assignee added the date and time of an appointment to the assignment.
   */
  hasAppointment = 'Has Appointment',
  /**
   * The date and time of the scheduled appointment.
   */
  appointment = 'Appointment',
  /**
   * An assignee added the date and time of an appointment to the assignment.
   */
  doesntHaveAppointment = "Doesn't Have Appointment",
  /**
   * A contacted date and time is added to the assignment.
   */
  contacted = 'Contacted',
  /**
   * The date and time the customer is contacted.
   */
  customerContacted = 'Customer Contacted',
  /**
   * The date and time of the initial contact with the insured.
   */
  firstContact = 'First Contact',
  /**
   * The date and time that an inspection occurred.
   */
  inspected = 'Inspected',
  /**
   * The date and time that an inspection occurred.
   */
  siteInspected = 'Site Inspected',
  /**
   * The date and time that an inspection occurred.
   */
  onsiteInspection = 'Onsite Inspection',
  /**
   * The target date to start the estimate.
   */
  targetStartDate = 'Target Start Date',
  /**
   * The date the estimate should be completed.
   */
  targetCompletionDate = 'Target Completion Date',
  /**
   * The date and time that the job started.
   */
  jobStarted = 'Job Started',
  /**
   * The date and time the job is completed.
   */
  jobCompleted = 'Job Completed',
  /**
   * This optional status informs both parties when the client or insured has refused the job.
   */
  jobNotSold = 'Not Sold',
  /**
   * Billing has not yet been sent to the client, but is being prepared to send.
   */
  billingPending = 'Billing Pending',
  /**
   * Billing is sent to the client.
   */
  clientBilled = 'Client Billed',
  /**
   * Payment is received.
   */
  paymentReceived = 'Payment Received',
  /**
   * Mitigation drying is started.
   */
  dryingStarted = 'Drying Started',
  /**
   * Mitigation drying is complete.
   */
  dryingCompleted = 'Drying Completed',
  /**
   * This read-only status indicates if an invoice is added by an XactAnalysis SP user.
   */
  invoicedNotYetInvoiced = 'Invoiced / Not Yet Invoiced',
  /**
   * A delay in the workflow process that means the assignment is not complete by the target completion date.
   */
  delayed = 'Delayed',
  /**
   * The estimate has not yet been marked QA approved.
   */
  qaNotYetApproved = 'QA Not Yet Approved',
  /**
   * The estimate has not yet been marked QA approved.
   */
  notYetAppr = 'Not Yet Appr.',
  /**
   * The date and time a quality assurance team member approves the assignment.
   */
  qaApproved = 'QA Approved',
  /**
   * The date and time a quality assurance team member approves the assignment.
   */
  assignmentIsQaApproved = 'Assignment is QA Approved',
  /**
   * The date and time a quality assurance team member rejects the assignment.
   */
  qaRejected = 'QA Rejected',
  /**
   * The date and time a quality assurance team member rejects the assignment.
   */
  assignmentIsQaRejected = 'Assignment is QA Rejected',
  /**
   * The estimate has not yet been marked Client Approved.
   */
  clientNotYetApproved = 'Client Not Yet Approved',
  /**
   * The date and time the client approved the estimate.
   */
  clientApproved = 'Client Approved',
  /**
   * The assignment is approved by the client.
   */
  assignmentIsClientApproved = 'Assignment is Client Approved',
  /**
   * The date and time the assignment is rejected by the client.
   */
  clientRejected = 'Client Rejected',
  /**
   * You can set up a personal rule to notify you when a client rejects an assignment.
   */
  assignmentIsClientRejected = 'Assignment is Client Rejected',
  // #region File statuses
  /**
   * The assignment is created or received in XactAnalysis via EDI and is not yet marked Closed.
   */
  open = 'Open',
  /**
   * The assignment is created or received in XactAnalysis via EDI and is not yet marked Closed.
   */
  fileOpen = 'File Open',
  /**
   * The assignment, previously marked File Closed in XactAnalysis, is subsequently marked back to File Reopened in the Details tab. A file may be reopened if something needs to be added after the initial completion.
   */
  reopened = 'Reopened',
  /**
   * The assignment, previously marked File Closed in XactAnalysis, is subsequently marked back to File Reopened in the Details tab. A file may be reopened if something needs to be added after the initial completion.
   */
  fileReopened = 'File Reopened',
  /**
   * The assignment is marked as closed.
   */
  closed = 'Closed',
  /**
   * The assignment is marked as closed.
   */
  fileClosed = 'File Closed',
  // #endregion File statuses
}
