import {
  Auth0ZoneTypeSchema,
  Cohort,
  CohortEnum,
  CohortEnumSchema,
  ProducerUserTypesEnumSchema,
  UserTypesEnumCanon,
} from '@eigtech/auth0-types'

import z from 'zod'
import { getCohortByPortalZone } from '../util/portalUtils.js'

export const PortalStatusSchema = z.enum([
  'ACTIVE',
  'ACTIVATING',
  'EXPIRED',
  'INACTIVE',
  'DEACTIVATING',
  'STAGED',
  'STAGING',
])
export const portalStatus = PortalStatusSchema.Values

export const PortalEntityTypeSchema = z.enum(['claim', 'job'])
export const PortalEntityType = PortalEntityTypeSchema.Enum

export const PortalZoneSchema = Auth0ZoneTypeSchema
export const portalZone = PortalZoneSchema.Values

export const PortalDeactivatedCodeEnumSchema = z.enum([
  'ENTITY_CLOSED',
  'EXPIRED',
  'MANUAL',
  'UNASSIGNED',
  'UNKNOWN',
  'USER_MERGED',
])
export const PortalDeactivatedCode = PortalDeactivatedCodeEnumSchema.Values

export const PortalProducerSchema = z.object({
  auth0Id: z.string(),
  createdBy: z.string(),
  dateCreated: z.string().datetime(),
  dateModified: z.string().datetime(),
  deactivatedCode: PortalDeactivatedCodeEnumSchema.optional(),
  deactivatedReason: z.string().optional(),
  email: z.string(),
  entityId: z.string(),
  entityPermissions: z.string().array(),
  entityType: PortalEntityTypeSchema,
  expireDate: z.string().datetime(),
  expiresAt: z.number(),
  externalUserId: z.string(),
  modifiedBy: z.string(),
  portalUserId: z.string(),
  status: PortalStatusSchema,
  zone: PortalZoneSchema,
  userType: ProducerUserTypesEnumSchema,
})

// DO NOT USE.  This mapping is only for historical events.
const cohortToUserTypeMap: Partial<Record<CohortEnum, UserTypesEnumCanon>> = {
  [Cohort.csr]: 'coordinator',
  [Cohort.estimator]: 'estimator',
  [Cohort.insured]: 'insured',
  [Cohort.super]: 'csrAdmin',
}

export const PortalConsumerInSchema = PortalProducerSchema.or(
  PortalProducerSchema.omit({ userType: true })
)
export const PortalConsumerSchema = PortalConsumerInSchema.transform((portal, ctx) => {
  if ('userType' in portal) {
    // all good
    return portal
  } else {
    const cohort = getCohortByPortalZone(portal.zone)
    const userType: UserTypesEnumCanon | undefined = cohortToUserTypeMap[cohort]
    if (userType) {
      return {
        ...portal,
        userType,
      }
    }
  }
  ctx.addIssue({
    code: z.ZodIssueCode.custom,
    message: `User type is required`,
    path: ['userType'],
  })
  return z.NEVER
})

export const StagedPortalProducerSchema = PortalProducerSchema.omit({
  auth0Id: true,
  email: true,
  portalUserId: true,
}).merge(
  z.object({
    expiresAt: PortalProducerSchema.shape.expiresAt.optional(), // TODO: remove optional for producer
    inactive: z.boolean(),
  })
)

export const StagedPortalConsumerInSchema = StagedPortalProducerSchema.or(
  StagedPortalProducerSchema.omit({ userType: true }).merge(z.object({ cohort: CohortEnumSchema }))
)
export const StagedPortalConsumerSchema = StagedPortalConsumerInSchema.transform((portal, ctx) => {
  if ('userType' in portal) {
    // all good
    return portal
  } else if ('cohort' in portal) {
    const { cohort, ...rest } = portal
    const userType: UserTypesEnumCanon | undefined = cohortToUserTypeMap[cohort]
    if (userType) {
      return {
        ...rest,
        userType,
      }
    }
  }
  ctx.addIssue({
    code: z.ZodIssueCode.custom,
    message: `User type is required`,
    path: ['userType'],
  })
  return z.NEVER
})

export const isPortal = (portal: unknown): portal is PortalConsumerIn => {
  return PortalConsumerInSchema.safeParse(portal).success
}
export const isStagedPortal = (portal: unknown): portal is StagedPortalConsumerIn => {
  return StagedPortalConsumerInSchema.safeParse(portal).success
}
export const ensureCompletePortal = (portal: unknown): PortalConsumer | undefined => {
  const parsed = PortalConsumerSchema.safeParse(portal)
  return parsed.success ? parsed.data : undefined
}
export const ensureStagedPortal = (portal: unknown): StagedPortalConsumer | undefined => {
  const parsed = StagedPortalConsumerSchema.safeParse(portal)
  return parsed.success ? parsed.data : undefined
}

export type PortalConsumerIn = z.infer<typeof PortalConsumerInSchema>
export type PortalConsumer = z.infer<typeof PortalConsumerSchema>
export type PortalProducer = z.infer<typeof PortalProducerSchema>
export type PortalStatusEnum = z.infer<typeof PortalStatusSchema>
export type PortalDeactivatedCodeEnum = z.infer<typeof PortalDeactivatedCodeEnumSchema>
export type PortalEntityTypeEnum = z.infer<typeof PortalEntityTypeSchema>
export type PortalZone = z.infer<typeof PortalZoneSchema>
/**
 * Staged portals are created when the email for a user is unknown
 */
export type StagedPortalConsumer = z.infer<typeof StagedPortalConsumerSchema>
export type StagedPortalProducer = z.infer<typeof StagedPortalProducerSchema>
export type StagedPortalConsumerIn = z.infer<typeof StagedPortalConsumerInSchema>
