import z from 'zod'
import {
  ClaimV1ChronicleType,
  ClaimV2ChronicleType,
  EstimateChronicleType,
} from './claimChronicles.js'
import { Chronicle } from './timeline.js'

export const ClaimInsuredChronicleTypeSchema = z.enum([
  // #region claim v2 events
  ClaimV2ChronicleType.claimCreated,
  ClaimV2ChronicleType.closed,
  ClaimV2ChronicleType.inspectionCompleted,
  // #endregion claim v2 events
  // #region claim v1 status events
  ClaimV1ChronicleType.carrierApprovedEstimate,
  ClaimV1ChronicleType.carrierApprovedEstimateWithExceptions,
  ClaimV1ChronicleType.clientApproved,
  ClaimV1ChronicleType.estimateSentToCarrier,
  ClaimV1ChronicleType.inAssignQueue,
  ClaimV1ChronicleType.jobCompleted,
  ClaimV1ChronicleType.jobContracted,
  ClaimV1ChronicleType.jobNotSold,
  ClaimV1ChronicleType.jobSold,
  ClaimV1ChronicleType.jobStarted,
  ClaimV1ChronicleType.qaApproved,
  // #endregion claim v1 status events
  // #region estimate events
  EstimateChronicleType.carrierReviewed,
  EstimateChronicleType.carrierReviewedWithExceptions,
  EstimateChronicleType.clientApproved,
  EstimateChronicleType.qaApproved,
  // #endregion estimate events
])

export const ClaimInsuredChronicleType = ClaimInsuredChronicleTypeSchema.Enum
export const isClaimInsuredChronicle = (
  chronicle: Pick<Chronicle, 'type'>
): chronicle is Chronicle & { type: ClaimInsuredChronicleTypeEnum } =>
  ClaimInsuredChronicleTypeSchema.safeParse(chronicle.type).success

export type ClaimInsuredChronicleTypeEnum = z.infer<typeof ClaimInsuredChronicleTypeSchema>

/**
 * The ClaimInsuredTimeline chronicle types before compilation
 */
export const ClaimInsuredSourceTypeSchema = z.enum([
  ...ClaimInsuredChronicleTypeSchema.options,
  ClaimV2ChronicleType.reopened,
])
export const ClaimInsuredSourceType = ClaimInsuredSourceTypeSchema.Enum
export type ClaimInsuredSourceTypeEnum = z.infer<typeof ClaimInsuredSourceTypeSchema>
export const isClaimInsuredSourceChronicle = (
  chronicle: Pick<Chronicle, 'type'>
): chronicle is Chronicle & { type: ClaimInsuredSourceTypeEnum } =>
  ClaimInsuredSourceTypeSchema.safeParse(chronicle.type).success
