import { ROUTER_PATHS } from '@eigtech/ui-estimator/lib/constants'
import { ComposedCard } from '@eigtech/ui-shared-dave'
import { getRouteApi } from '@eigtech/ui-shared-router'
import { ClaimDetailedTimeline as BaseClaimDetailedTimeline } from '@eigtech/ui-shared-timeline'

const Route = getRouteApi(ROUTER_PATHS.claimDetailTimeline.routeId)

export function ClaimDetailTimeline() {
  const { claimNumber } = Route.useParams()

  return (
    <ComposedCard heading="Claim Timeline">
      <BaseClaimDetailedTimeline
        claimNumber={claimNumber}
        pb="6"
        pt="4"
        spacing="10"
        timelineType="default"
      />
    </ComposedCard>
  )
}
