import z from 'zod'
import { makeSummaryFilterSchema } from './pagination.js'

export const TimelineSummaryFieldSchema = z.enum([
  'chronicleDate',
  'chronicleType',
  'fieldAdjusterId',
  'timelineType',
])
export const TimelineSummaryQueryFieldSchema = z.enum([
  ...TimelineSummaryFieldSchema.options,
  'timelineId',
])

export const TimelineSummaryFilterSchema = makeSummaryFilterSchema(TimelineSummaryFieldSchema)
export const TimelineSummaryFilterQuerySchema = makeSummaryFilterSchema(
  TimelineSummaryQueryFieldSchema
)

export type TimelineSummaryField = z.infer<typeof TimelineSummaryFieldSchema>
export type TimelineSummaryFilter = z.infer<typeof TimelineSummaryFilterSchema>
export type TimelineSummaryQueryField = z.infer<typeof TimelineSummaryQueryFieldSchema>
export type TimelineSummaryFilterQuery = z.infer<typeof TimelineSummaryFilterQuerySchema>
