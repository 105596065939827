import { ProducerUserTypesEnumSchema } from '@eigtech/auth0-types'
import z from 'zod'
import { PortalEntityTypeSchema, PortalZoneSchema } from '../base/portal.js'
import { PortalUserSchema } from '../base/user.js'
import { PortalsServiceEventBaseSchema, portalsServiceEventType } from './service.js'

const portalUserEventType = <V extends string | undefined>(eventName?: V) =>
  portalsServiceEventType('user', eventName)

export const PortalUserEventSchema = PortalsServiceEventBaseSchema.merge(
  z.object({
    type: portalUserEventType(),
    user: z.unknown(),
  })
)

export const PortalUserCreatedEventSchema = PortalUserEventSchema.merge(
  z.object({
    type: portalUserEventType('created'),
    user: PortalUserSchema.merge(
      z.object({
        entityType: PortalEntityTypeSchema.optional(),
        userType: ProducerUserTypesEnumSchema.optional(),
        zone: PortalZoneSchema.optional(),
      })
    ),
  })
)

export const PortalUserUpdatedEventSchema = PortalUserEventSchema.merge(
  z.object({
    type: portalUserEventType('updated'),
    user: PortalUserSchema,
  })
)

export const PortalUserDeletedEventSchema = PortalUserEventSchema.merge(
  z.object({
    type: portalUserEventType('deleted'),
    user: PortalUserSchema,
  })
)

export const PortalUserRestoredEventSchema = PortalUserEventSchema.merge(
  z.object({
    type: portalUserEventType('restored'),
    user: PortalUserSchema,
  })
)

export type PortalUserEvent = z.infer<typeof PortalUserEventSchema>
export type PortalUserCreatedEvent = z.infer<typeof PortalUserCreatedEventSchema>
export type PortalUserDeletedEvent = z.infer<typeof PortalUserDeletedEventSchema>
export type PortalUserUpdatedEvent = z.infer<typeof PortalUserUpdatedEventSchema>
export type PortalUserRestoredEvent = z.infer<typeof PortalUserRestoredEventSchema>
