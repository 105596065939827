import { Handler } from 'aws-lambda'
import z from 'zod'
import { StepFunctionTaskContextSchema } from './context.js'
import { IncrementPageResponseSchema } from './increment.js'

export const BuildTimelinesInputSchema = z.object({
  incrementPageResults: IncrementPageResponseSchema,
})
export type BuildTimelinesInput = z.infer<typeof BuildTimelinesInputSchema>

export const BuildTimelinesRequestSchema = z.object({
  context: StepFunctionTaskContextSchema,
  input: BuildTimelinesInputSchema,
})
export type BuildTimelinesRequest = z.infer<typeof BuildTimelinesRequestSchema>
export interface BuildTimelinesHandler extends Handler<BuildTimelinesRequest, void> {}
