import { isISOish } from '@eigtech/function-utils'
import { ZodSchema, z } from 'zod'
import { summarizerServiceCorn } from '../base/service.js'

export const TimelineSourceSchema = z.enum(['Verisk', 'CSR', 'TPLAP', 'Estimator'])
export const TimelineSource = TimelineSourceSchema.Enum

export const TimelineTypeSchema = z.enum([
  'default',
  'insured',
  'status',
  'estimator',
  'reviewer',
  'reporting',
])
export const TimelineType = TimelineTypeSchema.Enum

export const TimelineEntityTypeSchema = z.enum(['claim', 'job'])
export const TimelineEntityType = TimelineEntityTypeSchema.Enum
export type TimelineEntityTypeEnum = z.infer<typeof TimelineEntityTypeSchema>

export const ChroniclePrecursorSchema = z.object({
  createdBy: z.string().optional(),
  date: z.string().refine(isISOish, { message: 'not an ISO date' }),
  details: z.record(z.unknown()).optional(),
  domain: TimelineSourceSchema.or(z.string()),
  type: z.string(),
})

export const ChronicleLabelSchema = z.string()

export const ChronicleSchema = ChroniclePrecursorSchema.merge(
  z.object({
    label: ChronicleLabelSchema,
  })
)

export const TimelineSchema = z.object({
  chronicles: z.array(ChronicleSchema),
  entityId: z.string(), // ie. claimNumber, jobId
  entityType: TimelineEntityTypeSchema,
  timelineId: summarizerServiceCorn(),
  timelineType: TimelineTypeSchema,
})

export type Chronicle = z.infer<typeof ChronicleSchema>
export type ChronicleLabel = z.infer<typeof ChronicleLabelSchema>
export type ChroniclePrecursor = z.infer<typeof ChroniclePrecursorSchema>
export type Timeline = z.infer<typeof TimelineSchema>
export type TimelineSourceEnum = z.infer<typeof TimelineSourceSchema>
export type TimelineTypeEnum = z.infer<typeof TimelineTypeSchema>

/* eslint-disable @typescript-eslint/no-explicit-any */
export type ChronicleEventMapping = {
  schema: ZodSchema<any, any, any>
  handler: (event: any) => ChroniclePrecursor | undefined
}
