import z from 'zod'
import { makeSummaryFilterSchema, makeSummaryParamsSchema } from './pagination.js'

export const InspectionSummaryFieldSchema = z.enum([
  'inspectionId',
  'entityId',
  'dateCompleted',
  'dateCanceled',
  'scheduledDate',
  'status',
  'inspectorRole',
  'fieldAdjusterId',
])

export const InspectionSummaryFilterSchema = makeSummaryFilterSchema(InspectionSummaryFieldSchema)
export const InspectionSummariesParamsSchema = makeSummaryParamsSchema(
  InspectionSummaryFieldSchema,
  InspectionSummaryFilterSchema
)
export type InspectionSummaryField = z.infer<typeof InspectionSummaryFieldSchema>
export type InspectionSummaryFilter = z.infer<typeof InspectionSummaryFilterSchema>
export type InspectionSummariesParams = z.infer<typeof InspectionSummariesParamsSchema>
