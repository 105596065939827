import z from 'zod'
import { JobRestorationChronicleType } from './jobChronicles.js'
import { Chronicle } from './timeline.js'

export const JobInsuredChronicleTypeSchema = z.enum([
  JobRestorationChronicleType.appointmentCompleted,
  JobRestorationChronicleType.appointmentScheduled,
  JobRestorationChronicleType.cancelled,
  JobRestorationChronicleType.completed,
  JobRestorationChronicleType.received,
  JobRestorationChronicleType.withdrawn,
])

export const JobInsuredChronicleType = JobInsuredChronicleTypeSchema.Enum
export const isJobInsuredChronicle = (
  chronicle: Pick<Chronicle, 'type'>
): chronicle is Chronicle & { type: JobInsuredChronicleTypeEnum } =>
  JobInsuredChronicleTypeSchema.safeParse(chronicle.type).success

export type JobInsuredChronicleTypeEnum = z.infer<typeof JobInsuredChronicleTypeSchema>

/**
 * The JobInsuredTimeline chronicle types before compilation
 */
export const JobInsuredSourceTypeSchema = z.enum([
  ...JobInsuredChronicleTypeSchema.options,
  JobRestorationChronicleType.reopened,
])
export const JobInsuredSourceType = JobInsuredSourceTypeSchema.Enum
export type JobInsuredSourceTypeEnum = z.infer<typeof JobInsuredSourceTypeSchema>
export const isJobInsuredSourceChronicle = (
  chronicle: Pick<Chronicle, 'type'>
): chronicle is Chronicle & { type: JobInsuredSourceTypeEnum } =>
  JobInsuredSourceTypeSchema.safeParse(chronicle.type).success
